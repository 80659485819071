<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Processos</h4>
            <button type="button" class="close" data-dismiss="modal" @click="fechar">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group" >
                  <label for="nome" class="required">Nome</label>
                  <input v-model="processo.nome" type="text" class="form-control" name="nome" id="nome">
                </div>
              </div>
              <div class="col align-self-end col form-group">
                <button type="button" @click="salvar" class="btn btn-primary float-right">SALVAR</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <MyTable
                  :data="this.processos"
                  :columns="columns"
                  :itemsPerPage="10">
                  <template #column="{item, column}">
                    <span v-if="column.field == 'actions'">
                      <i class="fas fa-edit mr-3 btn btn-warning" title="Editar sala" @click="editarItem(item)"></i>
                      <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar sala" @click="deletarItem(item)"></i>
                    </span>
                    <span v-else>
                      {{item[column.field]}}
                    </span>
                  </template>
                </MyTable>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
         
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MyTable from './../../../../uicomponents/MyTable'
import normasRepository from './../../../../services/api/normasRepository'
export default {
  components: {
    MyTable
  },
  props: ['propNorma'],
  created () {
    this.norma = this.propNorma
    this.fetch()
  },
  data () {
    return {
      norma: {},
      processo: {
        id: 0,
        nome: '',
        norma_id: 0
      },
      processos: [],
      columns: [
        {
          label: 'Processo',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ]
    }
  },
  methods: {
    fetch () {
      this.$store.commit("setSplashScreen", true)
      normasRepository.listarProcessosPorNormaId(this.norma.id).then(response => {
        this.$store.commit("setSplashScreen", false)
        if(response.data.success) {
          this.processos = response.data['data']
          console.log(this.processos)
        }
      }).catch( () => {
        this.$store.commit("setSplashScreen", false)
      })
    },
    fechar() {
      this.processo = {id: 0, nome: '', norma_id: 0}
      this.norma = {}
      this.$emit('close')
    },
    editarItem (item) {
      this.processo = Object.assign({}, item)
    },
    deletarItem (item) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover',
        text: `Deseja deletar ${item.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarProcesso(item)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarProcesso (item) {
      this.$store.commit("setSplashScreen", true)
      normasRepository.deletarProcesso(item.id).then((response) => {
        this.$store.commit("setSplashScreen", false)
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.$store.commit("setSplashScreen", false)
        return false
      })
    },
    salvar () {
      if(this.processo.nome == '') return
			if (this.processo.id > 0) {
        normasRepository.atualizarProcesso(this.processo.id, this.processo).then(response => {
          if (response.data['success']) {
            this.$swal({
              icon: 'success',
              text: 'Cadastro atualizado com sucesso!'
            }).then( () => {
              this.processo.id = 0
              this.processo.nome = ''
              this.fetch()
            })
          }
        }).catch(error => {
          console.log(error)
          this.$swal({
            icon: 'error',
            title: 'Erro no cadastro!',
            text: error.message
          })
        })
      } else {
        this.$store.commit("setSplashScreen", true)
        this.processo.norma_id = this.norma.id
        normasRepository.salvarProcesso(this.processo).then(response => {
          this.$store.commit("setSplashScreen", false)
          if (response.data['success']) {
            this.$swal({
              icon: 'success',
              text: 'Cadastrado realizado com sucesso!'
            }).then( () => {
              this.processo.id = 0
              this.processo.nome = ''
              this.fetch()
            })
          } else {
            this.$swal({
              icon: 'error',
              text: 'Não foi possível realizar o cadastro!'
            })
          }
        }).catch(error => {
          this.$store.commit("setSplashScreen", false)
          this.$swal({
            icon: 'error',
            title: 'Erro no cadastro!',
            text: error.message
          })
        })
      }
		}
  }
}
</script>

<style>
  
</style>
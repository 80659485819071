<template>
  <div class="container-fluid card-body">
    <Processos v-if="exibirProcessos" @close="exibirProcessos = false" :propNorma="normaSelecionada"/>
    <div class="row">
      <div class="col">
        <h1>Normas</h1>
      </div>
      <div class="col">
        <router-link to="normas/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color" v-if="permissoes > 1">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :searchable="true"
          :data="normas"
          :columns="columns"
          :itemsPerPage="10">
          <template #column="{item, column}">
            <span v-if="column.field == 'actions'">
              <i class="fas fa-project-diagram mr-3 btn btn-info" title="Processos" @click="verProcessos(item)" v-if="permissoes >= 1"></i>
              <i class="fas fa-edit mr-3 btn btn-warning" title="Editar norma" @click="editarItem(item.id)" v-if="permissoes > 1"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar norma" @click="deletarItem(item)" v-if="permissoes > 2"></i>
            </span>
            <span v-else>
              {{item[column.field]}}
            </span>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import Processos from './processos/Processos'
import MyTable from './../../../uicomponents/MyTable'
import normasRepository from './../../../services/api/normasRepository'

export default {
  name: 'Empresas',
  components: {
    MyTable,
    Processos,
    
  },
  data(){
    return {
      permissoes: null,
      normas: [],
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      exibirProcessos: false,
      normaSelecionada: {}
    }
  },
  created () {
    this.fetch()
    this.permissoes = this.$store.getters.permissoes['Treinamento']['Normas']
  },
  methods: {
    fecharCadastroNorma(refresh = false) {
      this.cadastrarNorma = false
      this.normaSelecionada = null
      if(refresh) {
        this.fetch()
      }
    },
    verProcessos (item) {
      this.normaSelecionada = item
      this.exibirProcessos = true
    },
    editarItem (id) {
      this.$router.push({ name: 'TreinamentoNormasCadastro', query: { id: id } })
    },
    deletarItem (item) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover local de treinamento',
        text: `Deseja deletar o local ${item.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarNorma(item)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    async deletarNorma (item) {
      this.$store.commit("setSplashScreen", true)
      let responseProcessos = await normasRepository.listarProcessosPorNormaId(item.id)
      this.$store.commit("setSplashScreen", false)
      if(responseProcessos.data.success && (responseProcessos.data.data == null || responseProcessos.data.data.length == 0)) {
        this.$store.commit("setSplashScreen", true)
        normasRepository.deletarNorma(item.id).then((response) => {
          this.$store.commit("setSplashScreen", false)
          return response.data['success']
        }).catch(() => {
          this.$store.commit("setSplashScreen", false)
          return false
        })
      } else {
        this.$swal({
          icon: 'info',
          text: 'Não foi possível deletar a norma. Existem processos vinculados!'
        })
      }
      
    },
    fetch () {
      this.$store.commit("setSplashScreen", true)
      normasRepository.listarNormas().then(response => {
        this.normas = response.data['data']
        this.$store.commit("setSplashScreen", false)
      }).catch( () => {
        this.$store.commit("setSplashScreen", false)
      })
    }
  }
}
</script>

<style>

</style>